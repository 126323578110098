#arrow,
#arrow::before {
  position: absolute;
  width: 12px;
  height: 12px;
  background: inherit;
}

#arrow {
  visibility: hidden;
}

#arrow::before {
  visibility: visible;
  content: "";
  transform: rotate(45deg);
}

#tooltip[data-popper-placement^="top"] > #arrow {
  bottom: -8px;
}

#tooltip[data-popper-placement^="top"] > #arrow,
#tooltip[data-popper-placement^="top"] > #arrow::before {
  border-right: inherit;
  border-bottom: inherit;
}

#tooltip[data-popper-placement^="bottom"] > #arrow {
  top: -8px;
}

#tooltip[data-popper-placement^="bottom"] > #arrow,
#tooltip[data-popper-placement^="bottom"] > #arrow::before {
  border-left: inherit;
  border-top: inherit;
}

#tooltip[data-popper-placement^="left"] > #arrow {
  right: -8px;
}

#tooltip[data-popper-placement^="left"] > #arrow,
#tooltip[data-popper-placement^="left"] > #arrow::before {
  border-top: inherit;
  border-right: inherit;
}

#tooltip[data-popper-placement^="right"] > #arrow {
  left: -8px;
}

#tooltip[data-popper-placement^="right"] > #arrow,
#tooltip[data-popper-placement^="right"] > #arrow::before {
  border-bottom: inherit;
  border-left: inherit;
}
